import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MapList from "./MapList";
import ls from 'local-storage';

export default class SearchMaps extends Component {
    constructor(props) {
        super(props);

        this.mapList = null;
        this.isUser = this.props.user;

        let searchParams = this.defaultSearchParams();

        let explain = {
            explainText: '',
            explainMap: '',
            explainName: '',
            explainDesc: '',
            explainOwnMaps: ''
        };

        this.state = {
            advanced: false,
            searchParams: searchParams,
            searching: false,
            interactive: false,
            explain: explain,
            showExplain: ls('mapsearch.showexplain') || false
        };

        this.searchParamsFromUrl();
        let advanced;
        if ((this.state.searchParams.sm
            + this.state.searchParams.sn
            + this.state.searchParams.sd).trim() !== '') {
            advanced = true;
        } else if (this.state.searchParams.search.trim() !== '') {
            advanced = false;
        } else {
            advanced = this.props.restore ?
                ls.get('mapsearch.searchtype') === 'advanced' : false;
        }
        this.state.advanced = advanced;

        this.makeEplain();
    }

    componentDidMount() {
        window.addEventListener('popstate', this.handlePopState);
        // Neem de url params over in de inputs
        // en start search als er searchparams zijn
        if (this.searchParamsFromUrl()) {
            this.search(false);
        }
        if (this.mapList) {
            this.mapList.onPageChanged = this.handlePageChanged
        }
        $('[data-toggle-tt="tooltip"]').tooltip();
    }
    componentWillUnmount() {
        window.removeEventListener('popstate', this.handlePopState);
    }

    componentDidUpdate() {
        $('[data-toggle-tt="tooltip"]').tooltip();
    }

    handlePopState = (e) => {
        this.searchParamsFromUrl();
        this.search(false, true);
    };

    defaultSearchParams() {
        return {
            search: '',                   // search
            sm: '',                       // searchMap
            scas: 'allwords',             // searchCombAllSome
            smas: 'all',                  // searchMapAllSome
            smmm: 'must',                 // searchMapMustMay
            sn: '',                       // searchName
            snas: 'all',                  // searchNameAllSome
            snmm: 'must',                 // searchNameMustMay
            sd: '',                       // searchDesc
            sdas: 'all',                  // searchDescAllSome
            sdmm: 'must',                 // searchDescMustMay
            om: '0',                      // searchOwn
            page: 1,
        };
    }

    /**
     * N.B. Het iframe is om het form te kunnen submitten zonder dat er
     * een echte action wordt uitgevoerd. Dit is om de autocomplete
     * van de inputs te laten werken.
     */
    render() {
        const searchEmpty = this.emptySearch();

        return (
            <div>
                <div className="row float-right mb-1">
                    {this.state.advanced &&
                    <div className="col-md-auto align-self-center">
                        <a href="#" onClick={this.toggleExplain}>
                            {this.state.showExplain ? lang('Hide explanation') : lang('Show explanation')}
                        </a>
                    </div>}
                    <div className="col-md-auto align-self-center">
                        <a href="#" onClick={this.toggleSearchType}>
                            {this.state.advanced ? lang('Simple search') : lang('Advanced search')}
                        </a>
                    </div>
                </div>

                {this.state.advanced && this.state.showExplain && this.explanation()}

                <iframe name="blank" style={{display: 'none'}} src="about:blank"/>
                <form id="search-form" target="blank" action="about:blank">
                    {this.searchInputs(this.state.advanced, this.isUser)}

                    <div className="row float-right">
                        {this.state.searching && this.state.interactive &&
                        <div className="col-md-auto align-self-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}
                        <div className="col-md-auto align-self-center mb-3">
                            <button
                                onClick={() => {this.search(true);}}
                                className={"btn btn-primary" + (searchEmpty || this.state.searching ? ' disabled' : '')}
                                type="submit">
                                {lang('Search')}
                            </button>
                        </div>
                    </div>
                </form>
                {this.state.timings && this.timings()}

                <div style={{clear: 'both'}}>
                    <MapList
                        ref={(r) => {this.mapList = r;}}/>
                </div>
            </div>
        );
    }

    /**************************
     * methods
     **************************/
    handleChange = (e) => {
        let value;
        if (e.target.type === 'checkbox') {
            value = e.target.checked ? 1 : 0;
        } else {
            value = e.target.value;
        }

        this.state.searchParams[e.target.name] = value;
        this.state.advanced && this.makeEplain();
        this.setState({});
    };

    handlePageChanged = (page) => {
        this.state.searchParams.page = page;
        this.searchParamsToUrl();
    };

    toggleSearchType = (e) => {
        e.preventDefault();
        let advanced = !this.state.advanced;
        this.state.advanced = !this.state.advanced;
        this.state.searchParams = this.defaultSearchParams();
        this.makeEplain();
        ls('mapsearch.searchtype', this.state.advanced ? 'advanced' : 'simple');

        this.setState({}, () => {
            this.searchParamsToUrl();
        });
    };

    toggleExplain = (e) => {
        e.preventDefault();
        this.state.showExplain = !this.state.showExplain;
        this.state.showExplain && this.makeEplain();
        ls('mapsearch.showexplain', this.state.showExplain)

        this.setState({}, () => {
            if (this.state.showExplain) {
                $('.collapse').show();
            } else {
                $('.collapse').hide();
            }
        })
    };

    search = (interactive, fromPopState) => {
        fromPopState = fromPopState || false;
        /*
        let inputs = $('#search :input');
        let values = {};
        $inputs.each(function() {
            values[this.name] = $(this).val();
        });
        */

        if (this.emptySearch()) {
            return;
        }

        // Forceer interactive als er niet genavigeerd is
        // (dus bijvoorbeeld bij paste van een url of
        // bezoek via een link)
        // TODO: window.performance.navigation.type is deprecated
        // bij support voor iOS en Android vervangen door de nieuwe
        // PerformanceNavigation interface
        // noinspection JSDeprecatedSymbols
        if (!fromPopState && !interactive && window.performance.navigation
            && window.performance.navigation.type !==
            window.performance.navigation.TYPE_BACK_FORWARD) {
            interactive = true;
        }

        let searchParams = JSON.stringify(this.state.searchParams);
        this.setState({searching: true, interactive: interactive});
        axios.post('/mapsearchapi', {
            searchParams: searchParams,
            interactive: interactive
        })
            .then(response => {
                let mapIds = response.data.ids || null;
                let timings = response.data.timings || null;
                this.mapList.refreshList(mapIds, this.state.searchParams.page, () => {
                    this.setState({searching: false, timings: timings});

                    if (!fromPopState) {
                        this.searchParamsToUrl();
                    }
                });

            })
            .catch(error => {
                this.setState({searching: false});
                //console.log(error);
            });
    };

    emptySearch() {
        return (
            this.state.searchParams['search'] +
            this.state.searchParams['sm'] +
            this.state.searchParams['sn'] +
            this.state.searchParams['sd']
        ).trim() === '';
    }

    emptyParamValue(value) {
        return value === '' || value === '0' || value === 0
    }

    searchParamsToUrl() {
        const baseUrl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname;


        let urlParams = {};
        if (this.state.advanced) {
            if (this.state.searchParams.sm) {
                urlParams.sm = this.state.searchParams.sm;
                urlParams.smas = this.state.searchParams.smas;
                urlParams.smmm = this.state.searchParams.smmm;
            }
            if (this.state.searchParams.sn) {
                urlParams.sn = this.state.searchParams.sn;
                urlParams.snas = this.state.searchParams.snas;
                urlParams.snmm = this.state.searchParams.snmm;
            }
            if (this.state.searchParams.sd) {
                urlParams.sd = this.state.searchParams.sd;
                urlParams.sdas = this.state.searchParams.sdas;
                urlParams.sdmm = this.state.searchParams.sdmm;
            }
        } else {
            urlParams.search = this.state.searchParams.search;
            urlParams.scas = this.state.searchParams.scas;
        }
        urlParams.om = this.state.searchParams.om;
        urlParams.page = this.state.searchParams.page;

        let paramStr = '';
        for (let param in urlParams) {
            let val = this.state.searchParams[param];
            if (!this.emptyParamValue(val)) {
                if (paramStr !== '') {
                    paramStr += '&';
                }

                // Vervang alle spaties in search door '+'
                if (typeof val === 'string') {
                    val = val.replace(/ /g, '+');
                }
                paramStr += param + '=' + val;
            }
        }

        let newUrl = baseUrl + (paramStr ? '?' + paramStr : '');
        window.history.pushState({ path: newUrl }, '', newUrl);
    }

    searchParamsFromUrl () {
        let paramStr = window.location.search + '&';
        let hasSearchParams = false;
        let searchParams = this.defaultSearchParams();

        for (let param in searchParams) {
            let startPos = paramStr.indexOf(param);
            if (startPos >= 0) {
                let endPos = paramStr.indexOf('&', startPos);
                if (endPos >=0) {
                    let urlParam = paramStr.substring(startPos, endPos);
                    let val = urlParam.replace(param + '=', '');
                    let prm = urlParam.replace('=' + val, '');

                    if (searchParams.hasOwnProperty(prm)) {
                        hasSearchParams = true;
                        val = val.replace(/\+/g, ' ');
                        //val = decodeURIComponent(val);
                        searchParams[prm] = val;
                    }
                    //console.log(urlParam);
                }
            }
        }
        this.state.searchParams = searchParams;
        return hasSearchParams;
    }

    makeEplain = () => {
        let searchMap = this.state.searchParams.sm;
        let searchName = this.state.searchParams.sn;
        let searchDesc = this.state.searchParams.sd;

        let searchCombAll = this.state.searchParams.scas === 'all';

        let searchMapAll = this.state.searchParams.smas === 'all';
        let searchNameAll = this.state.searchParams.snas === 'all';
        let searchDescAll = this.state.searchParams.sdas === 'all';
        let searchMapMust = this.state.searchParams.smmm === 'must';
        let searchNameMust = this.state.searchParams.snmm === 'must';
        let searchDescMust = this.state.searchParams.sdmm === 'must';
        let searchOwnMaps = this.state.searchParams.om  === 1;

        this.state.explain.explainMap =
            searchMap ?
                (searchMapAll ? lang('All of the words') : lang('Some of the words')) + ' ' +
                '"' + searchMap + '"' + ' ' +
                (searchMapMust ? lang('must be') : lang('may be')) + ' ' +
                lang('on the map') : '';
        this.state.explain.explainName =
            searchName ?
                (searchNameAll ? lang('All of the words') : lang('Some of the words')) + ' ' +
                '"' + searchName + '"' + ' ' +
                (searchNameMust ? lang('must be') : lang('may be')) + ' ' +
                lang('in the name') : '';
        this.state.explain.explainDesc =
            searchDesc ?
                (searchDescAll ? lang('All of the words') : lang('Some of the words')) + ' ' +
                '"' + searchDesc + '"' + ' ' +
                (searchDescMust ? lang('must be') : lang('may be')) + ' ' +
                lang('in the description') : '';
        this.state.explain.explainOwnMaps = searchOwnMaps ? lang('Search only in my own maps') : '';

        this.state.explain.explainText =
            (this.state.explain.explainMap ||
                this.state.explain.explainName ||
                this.state.explain.explainDesc) ? '' :
                lang('Enter some search terms to get an explanation of your query.');
    };


    /**************************
     * componenten
     **************************/
    searchInputs(advanced) {
        let inputs;

        if (advanced) {
            inputs =
                <div style={{clear: 'both'}}>
                    {this.searchFieldAdvanced('n', 'In the name')}
                    {this.searchFieldAdvanced('d', 'In the description')}
                    {this.searchFieldAdvanced('m', 'On the map')}
                    {this.isUser && this.ownMaps()}
                </div>
        } else {
            inputs =
                <div style={{clear: 'both'}}>
                    <input type="search"
                           name="search"
                           id="search"
                           autoComplete={'searchmap-search'}
                           value={this.state.searchParams['search']}
                           onChange={this.handleChange}
                           className="form-control"
                           placeholder={lang('Search for..')}
                    />
                    <div style={{display: 'flex', marginLeft: '15px'}}>
                    {this.allSome()}
                    {<div className="mr-3"/>}
                    {this.isUser && this.ownMaps()}
                    </div>
                    {!this.isUser && <div className="mb-3"/>}

                </div>
        }

        return inputs;
    }

    allSome() {
        return (
            <div className="form-group row mb-0">
                {this.radioSetPlain('c', 'AllWords', 'SomeWords')}
            </div>
        )
    }


    ownMaps() {
        return (
            <div className="form-group row mb-0">
                <div className="input-group input-group-sm col-sm-12">
                    <div className="form-check form-check-inline form-control-sm">
                        <input onChange={this.handleChange} type="checkbox" value="1" className="form-check-input" name="om" checked={this.state.searchParams['om'] == 1}
                        id="searchOwnMaps"/>
                        <label className="form-check-label" htmlFor="searchOwnMaps">{lang('Own maps')}</label>
                    </div>
                </div>
            </div>
        )
    }

    searchFieldAdvanced(type, label) {
        label = lang(label);
        const name = 's' + type;
        return (
            <div>
                <div className="form-group row">
                    <label htmlFor={name} className="col-form-label col-form-label-sm col-sm-2 text-nowrap">{label}</label>
                    <div className="input-group input-group-sm col-sm-10">
                        <div className="input-group-prepend">
                            {this.radioSet(type, 'All', 'Some')}
                        </div>
                        <input
                            type="search"
                            name={name}
                            id={name}
                            autoComplete={'searchmap-' + name}
                            value={this.state.searchParams[name]}
                            onChange={this.handleChange}
                            className="form-control form-control-sm"
                        />

                        <div className="input-group-append">
                            {this.radioSet(type, 'Must', 'May')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    radioSet(type, choice1, choice2) {
        const name = 's' + type + choice1.charAt(0).toLowerCase() + choice2.charAt(0).toLocaleLowerCase();
        const id1 = name + choice1;
        const id2 = name + choice2;
        const label1 = lang(choice1);
        const label2 = lang(choice2);
        const value1 = choice1.toLowerCase();
        const value2 = choice2.toLowerCase();
        return (
            <div className="form-check form-check-inline input-group-text mr-0">
                <input onChange={this.handleChange}
                       className="form-check-input"
                       type="radio"
                       id={id1}
                       name={name}
                       value={value1}
                       checked={this.state.searchParams[name] === value1}/>
                <label htmlFor={id1} className="form-check-label">{label1}</label>
                <div style={{marginRight: '0.7em'}}/>
                <input onChange={this.handleChange}
                       className="form-check-input"
                       type="radio"
                       id={id2}
                       name={name}
                       value={value2}
                       checked={this.state.searchParams[name] === value2}/>
                <label htmlFor={id2} className="form-check-label">{label2}</label>
            </div>
        );
    }

    radioSetPlain(type, choice1, choice2) {
        //debugger
        const name = 's' + type + choice1.charAt(0).toLowerCase() + choice2.charAt(0).toLocaleLowerCase();
        const id1 = name + choice1;
        const id2 = name + choice2;
        const label1 = lang(choice1);
        const label2 = lang(choice2);
        const value1 = choice1.toLowerCase();
        const value2 = choice2.toLowerCase();
        return (
            <div className="form-check form-check-inline">
                <input onChange={this.handleChange}
                       className="form-check-input"
                       type="radio"
                       id={id1}
                       name={name}
                       value={value1}
                       checked={this.state.searchParams[name] === value1}/>
                <label htmlFor={id1} className="form-check-label">{label1}</label>
                <div style={{marginRight: '0.7em'}}/>
                <input onChange={this.handleChange}
                       className="form-check-input"
                       type="radio"
                       id={id2}
                       name={name}
                       value={value2}
                       checked={this.state.searchParams[name] === value2}/>
                <label htmlFor={id2} className="form-check-label">{label2}</label>
            </div>
        );
    }

    explanation() {
        return (
            <div style={{clear: 'both'}}
                 className={"collapse" + (this.state.showExplain ? " show" : "")}
                 id="collapseExplain">
                <div className="card card-body" style={{padding: '0.6em'}}>
                    <small>
                        {this.state.explain.explainText &&
                            <div>{this.state.explain.explainText}</div>}
                        {this.state.explain.explainMap &&
                            <div>{this.state.explain.explainMap}</div>}
                        {this.state.explain.explainName &&
                            <div>{this.state.explain.explainName}</div>}
                        {this.state.explain.explainDesc &&
                            <div>{this.state.explain.explainDesc}</div>}
                        {!this.state.explain.explainText
                            && this.state.explain.explainOwnMaps &&
                            <div>{this.state.explain.explainOwnMaps}</div>}
                    </small>
                </div>
                <div className="mb-3"/>
            </div>
        )
    }

    timings() {
        return (
            <div style={{marginBottom: '20px'}}>
                <table style={{fontSize: '12px'}}>
                    <thead>
                        <tr>
                            <th>Total</th>
                            <th>SQL</th>
                            <th>Cache</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{paddingRight: '50px'}}>{this.state.timings.total}</td>
                            <td style={{paddingRight: '50px'}}>{this.state.timings.sql}</td>
                            <td style={{paddingRight: '50px'}}>{this.state.timings.fromCache ? 'yes' : 'no'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

/**
 * Attach to DOM
 */
const element = document.getElementById('search-maps');
if (element) {
    const props = Object.assign({}, element.dataset);
    ReactDOM.render(<SearchMaps {...props} />, element);
}
