import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { CSSTransition } from 'react-transition-group';
import ls from 'local-storage';

/**
 * MapList
 * Ontvangt via refreshList() een lijst met mapIds
 * Per pagina worden de map details opgevraagd en getoond
 */

export default class MapList extends Component {
    constructor(props) {
        super(props);

        this.mapIds = [];
        this.pageCount = 0;
        this.mapsPerPage = 0;
        this.onPageChanged = null;

        this.sizeClasses = {
            paginationContainerClass: 'pagination flex-wrap'
        };

        this.setSizeClasses();

        this.state = {
            mapDetails: null,
            currentPage: 0,
            transIn: false,
            newSearch: false,
            listView: ls('mapsearch.listview') || 'image',
            paginationContainerClass: this.sizeClasses.paginationContainerClass
        };

        this.handlePageClick = this.handlePageClick.bind(this);
    }


    handleWindowResize = () => {
        this.setSizeClasses();
        this.setState({paginationContainerClass: this.sizeClasses.paginationContainerClass})
    };

    setSizeClasses() {
        if (window.innerWidth < 640) {
            this.sizeClasses.paginationContainerClass = 'pagination pagination-sm flex-sm-wrap';
        } else if (window.innerWidth >= 640) {
            this.sizeClasses.paginationContainerClass = 'pagination flex-wrap';
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    render() {
        const hasResults = this.mapIds && this.mapIds.length > 0;
        const emptyResults = !hasResults && this.state.newSearch;
        if (emptyResults) {
            return <EmptySearch/>
        } else if (hasResults) {
            return (
                (this.mapIds && this.mapIds.length > 0) &&
                <div>
                    {this.paginate()}
                    <CSSTransition
                        in={this.state.transIn}
                        timeout={100}
                        classNames="trans-maplist"
                        onEnter={() => {
                            //console.log('entered');
                        }}
                        onExit={() => {
                            //console.log('exited');
                        }}
                    >
                        <div id="list-maps">
                            <table className="table table-borderless">
                                <tbody>
                                {this.state.mapDetails &&
                                this.state.mapDetails.map(
                                    (mapDetail, index) => this.mapDetail(mapDetail, index)
                                )}
                                </tbody>
                            </table>
                        </div>
                    </CSSTransition>
                    {this.paginate()}
                </div>
            );
        } else {
            return null;
        }
    }

    /**************************
     * methods
     **************************/
    refreshList(mapIds, page, callback) {
        this.mapIds = mapIds;
        page = Math.max(page, 1);

        this.mapsPerPage = ls('mapsearch.mapsperpage');
        this.mapsPerPage = this.mapsPerPage ? parseInt(this.mapsPerPage) : 10;
        try {
            this.pageCount = this.mapIds.length / this.mapsPerPage;
            this.getMapDetails(page, true, callback);
        } catch (e) {
            this.mapIds = [];
            this.pageCount = 0;
            if (callback) {
                callback();
            }
        }
    }

    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;

        await Promise.resolve(this.setState(() => ({ currentPage: page })));

        //console.log('page: ' + page);
        this.getMapDetails(page, false);

        // Geef de gewijzigde pagina door
        if (this.onPageChanged) {
            this.onPageChanged(page);
        }
    }

    async getMapDetails(page, newSearch, callback) {
        let startIdx = (page - 1) * this.mapsPerPage;
        let endIdx = Math.min(startIdx + this.mapsPerPage, this.mapIds.length);
        let maps = [];
        for (let i = startIdx; i < endIdx; i++) {
            maps.push(this.mapIds[i]);
        }

        let _this = this;

        // Zet de onderstaande code aan om de CSSTransition te activeren
        // await Promise.resolve(this.setState(() => ({transIn: false })));

        axios.post('/tpmapi', {
            ri: 'mapdetails',
            mapids: JSON.stringify(maps)})
            .then(response => {
                let mapDetails = response.data ? response.data : null;
                _this.setState({
                    newSearch: newSearch,
                    currentPage: page,
                    mapDetails: mapDetails,
                    transIn: true
                });
                if (callback) {
                    callback();
                }
            })
            .catch(function (error) {
                _this.setState({mapDetails: null});
                if (callback) {
                    callback();
                }
            });
    }

    changeItemsPerPage = (e) => {
        this.mapsPerPage = parseInt(e.target.value.replace(lang('per page'), ''));
        ls('mapsearch.mapsperpage', this.mapsPerPage);
        this.pageCount = this.mapIds.length / this.mapsPerPage;
        this.getMapDetails(1, false);

        // Geef de gewijzigde pagina door
        if (this.onPageChanged) {
            this.onPageChanged(1);
        }
    };

    changeListView = (e) => {
        //e.preventDefault();
        let value = e.currentTarget.querySelector("input").value;
        this.setState({listView: value});
        ls('mapsearch.listview', value);
        $(e.currentTarget).tooltip('hide');
    };

    /**************************
     * componenten
     **************************/
    paginate = () => {
        //console.log(this.state.listView);
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-auto mb-1">
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                        <label id="image" onClick={this.changeListView}
                               className={this.state.listView === 'image' ? 'btn btn-primary active' : 'btn btn-primary'}
                               title={lang('Thumbnails')} data-toggle-tt="tooltip">
                            <input type="radio" value="image" name="listView"/>
                                   <i className="fa fa-th-list"/>
                        </label>
                        <label id="list" onClick={this.changeListView}
                               className={this.state.listView === 'list' ? 'btn btn-primary active' : 'btn btn-primary'}
                               title={lang('List')} data-toggle-tt="tooltip">
                            <input type="radio" value="list" name="listView"/>
                                   <i className="fa fa-bars"/>
                        </label>
                    </div>
                </div>
                <div className="col-md-auto mb-1" style={{maxWidth: '40%'}}>
                    <select value={this.mapsPerPage + ' ' + lang('per page')}
                            onChange={this.changeItemsPerPage}
                            className={"form-control"}>
                        <option>{'5 ' + lang('per page')}</option>
                        <option>{'10 ' + lang('per page')}</option>
                        <option>{'20 ' + lang('per page')}</option>
                        <option>{'30 ' + lang('per page')}</option>
                    </select>
                </div>
                <div className="col-md-auto mb-1">
                    <ReactPaginate
                        pageCount={this.pageCount}
                        initialPage={this.state.currentPage - 1}
                        forcePage={this.state.currentPage - 1}
                        pageRangeDisplayed={7}
                        marginPagesDisplayed={2}
                        previousLabel="&#x276E;"
                        nextLabel="&#x276F;"
                        breakClassName={'page-item'}
                        breakLinkClassName={'page-link'}
                        containerClassName={this.state.paginationContainerClass}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                        onPageChange={this.handlePageClick}
                        disableInitialCallback={true}
                    />
                </div>
            </div>)
    };

    mapDetail(mapDetail, index) {
        // Let op! Index van de map wordt gebruikt als key,
        // dit voorkomt een rerender omdat als een key wijzigt
        let mapRef = mapDetail.mapinfo_alias ?
            mapDetail.mapinfo_alias.replace('mapinfo/', '') : mapDetail.mapid;
        let infoLink = '/map/' + mapRef;
        let imageLink = '/storage/mapthumbs/thumbl' + mapDetail.mapid + '.jpg';

        let details = '';
        switch (this.state.listView) {
            case 'image':
                details = (<
                    MapDetailsImage
                        key={index}
                        infoLink={infoLink}
                        imageLink={imageLink}
                        mapDetail={mapDetail}
                />);
                break;
            case 'list':
                details = (<
                    MapDetailsList
                        key={index}
                        infoLink={infoLink}
                        imageLink={imageLink}
                        mapDetail={mapDetail}
                />);
                break;
        }
        return details;
    }
}

const MapDetailsImage = (props) => (
    <tr id={props.mapId} >
        <td className="mapthumbnail" style={{width: '33%'}}>
            <a href={props.infoLink}>
                <div className="map-thumb-wrapper-l">
                    <img src={props.imageLink}
                         onError={(e) => {e.target.onerror=null; e.target.src="/storage/mapthumbs/thumbldefault.jpg"}}
                         alt={'mapthumb'}/>
                </div>
            </a>
        </td>
        <td style={{width: '33%'}}>
            <MapDetailsInfo
                infoLink={props.infoLink}
                mapDetail={props.mapDetail}
            />
        </td>
    </tr>
);

const MapDetailsList = (props) => (
    <tr id={props.mapId} >
        <td style={{width: '33%'}}>
            <MapDetailsInfo
                infoLink={props.infoLink}
                mapDetail={props.mapDetail}
            />
        </td>
    </tr>
);

const MapDetailsInfo = (props) => (
    <div>
        <div>
            <a href={props.infoLink}>{props.mapDetail.name}</a>
        </div>
        <div style={{color: '#808080', fontSize: '0.9em'}}>
            {
                props.mapDetail.username + " - " +
                props.mapDetail.created + ' - ' +
                props.mapDetail.timesplayed + ' x ' + lang('played')
            }
        </div>
        <div style={{maxHeight: '100px', overflowY: 'auto', overflowX: 'hidden'}}>
            {props.mapDetail.description}
        </div>
    </div>
);


const EmptySearch = (props) => (
    <div className="card text-center mt-5 mx-auto" style={{width: '18rem'}}>
        <div className="card-body">
            <h5 className="card-title">{lang('No maps found')}</h5>
            <p className="card-text">{lang('There were no maps found for your search.')}</p>
        </div>
    </div>
);
